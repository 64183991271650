<template>
    <div>
        <v-app-bar
            fixed
            color="primary">

             <v-btn 
                icon
                @click="back">
                <v-icon 
                    color="white" medium>mdi-arrow-right</v-icon>
            </v-btn>
            
            <v-toolbar-title class="white--text mr-n6 subtitle-2">
                {{ title }}
            </v-toolbar-title>

           
            <v-spacer/>

            <v-btn 
                icon
                @click="favPress(id)">
                <v-icon v-if="favorites.includes(id)" color="fav">mdi-star</v-icon>
                <v-icon v-else color="white">mdi-star-outline</v-icon>
            </v-btn>
           
            
        </v-app-bar>
    </div>

</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['title', 'id'],
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters('storeApp', ['favorites'])
    },
    methods: {
         ...mapActions('storeApp', ['addFav', 'removeFav', 'loadFav']),
        favPress(id) {
            if(this.favorites.includes(id)) {
                this.removeFav(id)
            } else {
                this.addFav(id)
            }
        },
        back() {
            if(window.history.length == 1) {
                this.$router.push('/')
            } else {
                this.$router.go(-1)
            }
            
        }
    },
    created() {
      this.loadFav()
    }
    
}
</script>